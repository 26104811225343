



//开发环节
const dev = {
 
//  TwoHttp:"http://192.168.1.183:9000",
TwoHttp:"http://system.hbjyjh.com:8081/jyjh/health-system",


}
//测试环节
const test = {
 TwoHttp:"http://192.168.1.183:9000",
}
//线上环节
const pro = {
  TwoHttp:"http://system.hbjyjh.com:8081/jyjh/health-system",
}

function getApi() {
  if (process.env.NODE_ENV === 'development') {
    return dev
  } else if (process.env.NODE_ENV === 'test') {
    return test
  } else {
    return pro
  }
}

const api = getApi()
/** 全局变量在此处配置 */
export default api


