<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <RightContactUs></RightContactUs>
    <Bottom v-if="isShow"></Bottom>
  </div>
</template>

<script>
  import Header from "./components/Header.vue";
  import Bottom from "./components/Bottom.vue";
  import RightContactUs from "./components/RightContactUs.vue";
  import {postUserBehavior} from "@/api/userBehavior/userBehavior.js"

  export default {
    name: "App",
    components: {Header, Bottom, RightContactUs},
    data() {
      return {
        route: null,
        isShow: true
      }
    },
    watch: {
      $route: {
        handler: function (route) {
          this.route = route.fullPath
          if (this.route == '/' || this.route == '/home-index' || this.route == '/solute-plan') {
            this.isShow = false
          } else {
            this.isShow = true
          }
        },
        immediate: true,
      },

    },
    created() {

      let query=this.$route.query;
      let lat = query.from ? query.from : null

      window.sessionStorage.setItem("from", lat)

      if (window.sessionStorage.getItem("num") == null) {
        window.sessionStorage.setItem("num", 0)
      }

      this.$mybus.on('userBehavior', (data) => {
        postUserBehavior(data).then(res => {
          console.log(res)
        })
      })

    },
    mounted() {

    }


  };
</script>

<style>
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0;
    padding: 0;
    /* width: 100%;
     height: 100%; */
    overflow-x: hidden;

  }
</style>
