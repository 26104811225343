<template>
	  <div class="contact-us">
		  <div class="one">
			 <div class="one-phone" @mousemove="mouseover()"  @mouseout="mouseout()">
			 </div>
			  <div class="one-weixin" @mousemove="mouse()"  @mouseout="mous()"></div>
		  </div>
		  <div    :class=" phone?'phonehover':'phone'">
			<div style="margin-left: 6px;">  联系电话&nbsp;<span>18607171010</span>
			   <img  @click="onCopy" class="imga " style="margin-bottom: -3px;" :src="copy? require('../assets/copy.png'):require('../assets/fuzhihover.png')"   />
			</div>
		  </div>
		  <div   :class=" weixin?'weixinhover':'weixin'">
			  <img src="../assets/dimensionalCode.png" style="width: 10rem;height:10rem;margin-top: 11%;margin-left: 4%;" />
		  </div>
	  </div>
</template>

<script>
export default {
  name: "RightContactUs",
  data() {
    return {
		phone:false,
		weixin:false,
		shouzhi:false,
		copy:true
    }
  },
  methods: {
	  onCopy:function(){
		          this.$copyText('0717-65238').then( ()=> {
		              this.copy=false

		          }, function () {

		          })
	  },
    mouseover() {
		if(this.weixin==true){
				   this.weixin=false
		}
		this.shouzhi=true
        this.phone = true

    },
    mouseout() {

   setTimeout(()=>{
	   this.shouzhi=false
	 this.phone = false
   },6000)
    },
	mouse(){
		if(this.phone==true){
			this.phone=false
		}
		this.shouzhi=true
	  this.weixin=true
	},
	mous(){

		this.shouzhi=false
		this.weixin=false
	}
  },
  watch:{
	  phone(newdata){
		  if(newdata==false){
			  this.copy=false
		  }else{
			  this.copy=true
		  }
	  }
  },
   created(){
   		setInterval(()=>{
   				  new Promise((resolve)=>{
   					  setTimeout(()=>{
   						  if(this.shouzhi!=true){
   						this.weixin=true
   						}
   						  resolve('成功')
   					  },4000)
   				  }).then((data)=>{
   					if(data=='成功'){
						setTimeout(()=>{
							if(this.shouzhi!=true){
						this.weixin=false
							}
						},3000)
					}
   				  })
   		},7000)
   }
};
</script>

<style  scoped>
	.imga{
		width: 20px;
		height: 20px;
	   margin-left: 5px;
	 cursor: pointer;
	}
	.phonehover>span{
		font-weight: 600;
		margin-right: -5px;
	}
.contact-us{
  position: fixed;
  top:76%;
  left: 6px;
  z-index: 2;
}
.weixinhover{

	left: 70px;
	animation: lefp 0.6s linear;
	width: 129px;
		height: 129px;
		background-image: url(../assets/pic_frame01.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		position: fixed;
		top: 81%;
}
.phonehover{
	width:210px ;
	height: 70px;

	font-size: 15px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #616161;
	line-height: 65px;
    z-index: 5;
	background-image: url(../assets/pic_frame02.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	position: fixed;
	left: 3.7%;
	top: 81%;
	animation: lefp 0.5s linear;

}
@keyframes lefp{
	           0% {
	               opacity: 0.1;

	           }
	           20% {
	              opacity: 0.2;

	           }
	           40% {
	               opacity: 0.4;

	           }
	           60% {
	              opacity: 0.6;

	           }
	           80% {
	             opacity: 0.8;

	           }
	           100% {
	              opacity: 1;

	           }
}
.one{
	width: 64px;
	height: 176px;
	background-image: url(../assets/pic_service.png);
	background-repeat:no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
    position: fixed;
	z-index: 3;
}
.one-phone{
	width: 36px;
	height:36px;
	background-image: url(../assets/icon_phone.png);
	background-repeat:no-repeat;
	background-position: center center;
	background-size: cover;
	 position: absolute;

	 top: 36%;
	 left: 20%;

}
.one-weixin{
	width: 36px;
	height:36px;
	background-repeat:no-repeat;
	background-image: url(../assets/icon_wechat.png);
	background-size: cover;
	background-position: center center;
	 position: absolute;
	 top: 66%;
	 left: 20%;
}
.phone{


	opacity: 0;

}
@keyframes timeBack{
	0% {
	    opacity: 1;
		right: 0px;
	}
	20% {
	   opacity: 0.8;
	   right: 10px;
	}
	40% {
	    opacity: 0.6;
	    right: 25px;
	}
	60% {
	   opacity: 0.4;
	   right: 40px;
	}
	80% {
	  opacity: 0.2;
	  right: 55px;
	}
	100% {
	   opacity: 0;
	   right: 70px;
	}
}
.weixin{
	left: 70px;
	/* animation: lefp 0.6s linear; */
	width: 129px;
		height: 129px;
		background-image: url(../assets/pic_frame01.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		position: fixed;
		top: 81%;
	opacity: 0;
	animation: timeBack  1s linear;
}


</style>
