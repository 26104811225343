<template>
  <div class="bottom">
    <div class="section12 flex-row ali-center jus-center">
      <div class="box1 flex-row jus-between">
        <div class="leftText flex-row">
          <div class="leftText1 flex-col jus-center">
            <div class="row row1 row2" @click="jump">鄂ICP备2021012368号-1</div>
            <div class="row">湖北九域聚合科技有限公司</div>
          </div>
          <div class="flex-col jus-center">
            <div class="row row1">联系电话：18607171010</div>
            <div class="row">公司地址：湖北省宜昌市新华广场A02栋18楼</div>
          </div>
        </div>
        <div class="rightImg flex-row ali-center">
          <div class="flex-row">
            <img class="iconWx" src="@/assets/icon-wx.png" alt="" srcset="">
            <div class="row">微信扫一扫添加客服微信号</div>
          </div>
          <img class="imgWX" src="@/assets/dimensionalCode.png" alt="" srcset="">
        </div>
      </div>
      <!-- <div class="layer2 flex-row">
        <span class="txt12">湖北九域聚合科技有限公司</span>
        <span class="txt12">鄂ICP备2021012368号-1</span>
        <span class="txt12">
          公司地址：湖北省宜昌市新华广场A02栋18楼
        </span>
        <span class="info19">电话号码：0717-6523833</span>
      </div>
      <div class="layer3 flex-row jus-around">

      </div>
      <div class="layer4 flex-row">
        <img
          class="pic2"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf9588af01af2d568cd70c8d047e631e8322ce68c03c9b9c0271e509ab2bec274"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom",
  methods:{
    jump(){
     window.open('https://beian.miit.gov.cn/')
    }
  }
};
</script>

<style lang="stylus" scoped>

.section12
  background-color rgba(2, 93, 145, 1)
  width 100%
  height 220px
  text-align left

  .box1
    width 1100px

  .leftText1
    width 240px

  .row
    width 100%
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 18px;

  .row1
    margin-bottom 30px

  .row2:hover
   color #000000
   cursor pointer

  .iconWx
    width 22px
    height 22px
    display block
    margin-right 10px

  .imgWX
    margin-left 30px
    width 135px
    height 133px
    display block
// .section12 {
//   z-index: 309;
//   height: 150px;
//   background-color: rgba(2, 93, 145, 1);
//   width: 100%;

//   .layer2 {
//     z-index: auto;
//     width: 1000px;
//     height: 18px;
//     justify-content: space-between;
//     // margin: 41px 0 0 429px;

//     .txt12 {
//       z-index: 311;
//       display: block;
//       overflow-wrap: break-word;
//       color: rgba(255, 255, 255, 0.85);
//       font-size: 13px;
//       white-space: nowrap;
//       line-height: 18px;
//       text-align: left;
//     }

//     .info19 {
//       z-index: 312;
//       display: block;
//       overflow-wrap: break-word;
//       color: rgba(255, 255, 255, 0.85);
//       font-size: 13px;
//       white-space: nowrap;
//       line-height: 18px;
//       text-align: left;
//     }

//     .txt13 {
//       z-index: 313;
//       display: block;
//       overflow-wrap: break-word;
//       color: rgba(255, 255, 255, 0.85);
//       font-size: 13px;
//       white-space: nowrap;
//       line-height: 18px;
//       text-align: left;
//     }

//     .word27 {
//       z-index: 314;
//       display: block;
//       overflow-wrap: break-word;
//       color: rgba(255, 255, 255, 0.85);
//       font-size: 13px;
//       white-space: nowrap;
//       line-height: 18px;
//       text-align: left;
//     }
//   }

//   .layer3 {
//     z-index: auto;
//     width: 468px;
//     height: 18px;
//     margin-top: 20px;

//     .txt14 {
//       z-index: 315;
//       display: block;
//       overflow-wrap: break-word;
//       color: rgba(255, 255, 255, 0.85);
//       font-size: 13px;
//       white-space: nowrap;
//       line-height: 18px;
//       text-align: left;
//     }
//   }

//   .layer4 {
//     z-index: auto;
//     width: 114px;
//     height: 22px;

//     .pic2 {
//       z-index: 316;
//       width: 114px;
//       height: 22px;
//     }
//   }
// }

// .pic2 {
//   z-index: 316;
//   width: 114px;
//   height: 22px;
// }
</style>
