import axios from 'axios'
import { Message } from 'element-ui'

// import store from '@/store'
// import { getToken } from '@/utils/auth'
//允许跨域携带cookie

axios.defaults.withCredentials = true;
// let loadingInstance
// create an axios instance
//创建一个axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 200000, // request timeout
})

// request interceptor
//在请求之前进行配置
// service.interceptors.request.use(
//   config => {
//     // do something before request is sent
//     loadingInstance = Loading.service({
//       lock: true,
//       text: 'Loading',
//       spinner: 'el-icon-loading',
//       background: 'rgba(0, 0, 0, 0.7)'
//     })
//     // let token =getToken() == undefined ? null : getToken()
//     // config.headers['Content-Type'] = 'application/json;charset=UTF-8'
//     // config.headers['global_request_id'] = null
//     // config.headers['parent_request_id'] = null
//     // config.headers['current_request_id'] = null
//     // config.headers['authentication_info'] = token
//     // config.headers['terminal_type'] = 'SYS'

//     // if (config.method === 'post') {
//     //   if (config.data !== undefined) {
//     //     config.data['subsystemIdentificationCode'] = store.getters.subsystemIdentificationCode
//     //   }else {
//     //     config.data = {'subsystemIdentificationCode': store.getters.subsystemIdentificationCode}
//     //   }
//     // } else {
//     //   if (config.params !== undefined) {
//     //     config.params['subsystemIdentificationCode'] = store.getters.subsystemIdentificationCode
//     //   }else {
//     //     config.params = {'subsystemIdentificationCode': store.getters.subsystemIdentificationCode}
//     //   }
//     // }
//     return config
//   },
//   error => {
//     loadingInstance.close()
//     // do something with request error
//     console.log(error) // for debug
//     return Promise.reject(error)
//   }
// )

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // loadingInstance.close()
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 200) {
      Message({
        message: res.msg || '系统异常，请重试!',
        type: 'error',
        duration: 5 * 1000
      })
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 'loginFail' || res.code === 50012 || res.code === 50014) {
      //   if(window.location.href.indexOf('/login') == -1) {
      //     store.dispatch('user/resetToken').then(() => {
      //         location.reload()

      //     })
      //   }

      // }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    // loadingInstance.close()
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
