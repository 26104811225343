import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/home-index'
    },
    {
      path: '/home-index',
      name: 'homeIndex',
      component: () => import('@/views/HomeIndex.vue')
    },
    {
      path: '/solute-plan',
      name: 'solutePlan',
      component: () => import('@/views/SolutePlan.vue')
    },
    {
      path: '/product-display',
      component: () => import('@/views/ProductDisplay.vue'),
      redirect: '/product-display/product-lists',
      children: [
        {
          path: 'product-lists',
          component: () => import('@/views/ProductLists.vue'),
          meta:{
            keepAlive:true // 需要缓存
        }
        },
        {
          path: 'product-detail',
          name: 'product-detail',
          component: () => import('@/views/ProductDetail.vue')
        }
      ]
    },
    {
      path: '/project-case',
      component: () => import('@/views/ProjectCase.vue'),
      redirect: '/project-case/project-lists',
      children: [
        {
          path: 'project-lists',
          component: () => import('@/views/ProjectLists.vue')
        },
        {
          path: 'project-detail',
          name: 'project-detail',
          component: () => import('@/views/ProjectDetail.vue')
        }
      ]
    },
    {
      path: '/about-us',
      component: () => import('@/views/AboutUs.vue')
    },
	{
		path:'/DynamiCall',
		component:()=>import('@/views/DynamiCall.vue'),
		redirect: '/DynamiCall/Dynamic',
		children:[
			{
			 	path: 'Dynamic',
				name:'Dynamic',
			 	// component: () => import('@/views/Dynamic.vue'),
				component:resolve => require(['@/views/Dynamic.vue'],resolve)
			},
			{ path:'DynamicDetails',
			  name:'DynamicDetails',
			  component: ()=>import('@/views/DynamicDetails.vue')
			},
			{ path:'DynamicSp',
			  name:'DynamicSp',
			  component: ()=>import('@/views/DynamicSp.vue')
			}
		]
	}
	
  ],
  

 
})
