import Vue from 'vue'
import App from './App.vue'
// import "./flexible"
import VueRouter from 'vue-router'
import router from './router'
Vue.use(VueRouter)
import less from 'less'
Vue.use(less)
import './common/common.css'
import './common/reset.styl'
//ie编译
import 'babel-polyfill'
import {
  Swiper as SwiperClass,
  Pagination,   //使用那个组件就在这里面添加
  Mousewheel,
  Autoplay,
  Navigation,
    EffectFade,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import 'swiper/swiper-bundle.css'
//这里面对应上面进行添加
SwiperClass.use([Pagination, Mousewheel, Autoplay,Navigation,EffectFade]);
Vue.use(getAwesomeSwiper(SwiperClass));
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// Vue.use(VueAwesomeSwiper)

//main.js
// import 'amfe-flexible/index.js'; //此文件在安装amfe-flexible模块时已自动创建，只需引入无需手动创建
import './amfe-flexible'

import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);

//粘贴复制功能
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import mitt from "mitt"
Vue.prototype.$mybus = new mitt()

//main.js 中
import VideoPlayer from 'vue-video-player'
//引入默认样式包

import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)





import './style/common.styl'
import store from './store/index.js'
import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage);
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app');


if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  window.location.href = "http://www.hbjyjh.com/h5/home-index"  //跳转到手机
}
