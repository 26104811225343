<template>
  <div class="header">
    <div class="section1 flex-row jus-between ali-center">
      <div class="flex-row ali-center imgBox">
        <img src="@/assets/logo.png" class="img1" />
        <!-- <img src="@/assets/logoText.png" alt="" srcset=""> -->
      </div>
      <div class="outer1 flex-row ali-center" >
        <router-link
          tag="div"
          :class="
            route.indexOf(item.path) != -1 ? 'menu-clickItem word2' : 'word2'
          "
          v-for="(item,index) in routerArr"
          :key="index"
          :to=" item.path"
          >{{ item.name }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
// import {getGwList} from "@/api/officialCases/officialCases.js";
export default {
  name: "header",
  data() {
    const routerArr = [
      { name: "首页", path: "/home-index" },
      { name: "解决方案", path: "/solute-plan" },
      { name: "产品中心", path: "/product-display" },
      { name: "经典案例", path: "/project-case" },
	  {name:"资讯中心",path:"/DynamiCall"},
      { name: "关于我们", path: "/about-us" },
    ];
	const listQuery={
		current:1,
		pageSize:4,
		listMode:true,
		keyWord:'',
	};
    return {
      route: null,
      routerArr,
	  listQuery,
	 listdata:{}
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.route = route.fullPath;
      },
      immediate: true,
    },
  },
  methods:{
	 //  getGwList(){
		// getGwList(this.listQuery).then((response)=>{
		// 	this.listdata=response.data
		// 	console.log(this.listdata)
		//  })
	 //  }
  },
  mounted() {
  }
};
</script>

<style lang="stylus" scoped>
.header {
  height: 80px;
  width: 100%;
  padding-left: 360px;
  padding-right: 360px;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: #fff;
  font-size: 14px;
  box-shadow 0 1px 10px 0px #ddd
  display flex
  justify-content center
}
// style="font-size: 14px;"
.imgBox img {
  display block
}

.img1 {
  margin-right 15px
  width 447px
  height 56px
}

.section1 {
  width  1526px
  .outer1 {
    .word2 {
      font-size 18px
      height: 80px;
      line-height: 80px;
      margin-left: 40px;
      margin-right: 40px;
      cursor: pointer;
    }

    .menu-clickItem {
      border-bottom: 2px solid #019FD8;
      color: #019FD8;
    }
  }
}
</style>